import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useAccount,
} from "wagmi";
import {
  Button,
  Card,
  Container,
  Grid,
  Link,
  Loading,
  Text,
} from "@nextui-org/react";

import ROYALTIES_ABI from "../abi/GhoulsRoyalties.json";

export function ReleaseFunds() {
  const { address, isConnecting, isDisconnected } = useAccount();
  const {
    config,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    addressOrName: "0x283FCF59FCEb9Da8AB7F94bc1dDC294164885d9C",
    contractInterface: ROYALTIES_ABI,
    functionName: "release(address)",
    args: address,
  });
  const { data, error, isError, write } = useContractWrite(config);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  if (isConnecting) return <div>Connecting...</div>;
  if (isDisconnected) return <div>Disconnected</div>;

  return (
    <Container alignItems="center">
      <Button
        shadow
        ripple
        color="primary"
        auto
        disabled={!write || isLoading}
        onClick={() => write?.()}
      >
        {isLoading ? <Loading color="currentColor" size="sm" /> : "Release ETH"}
      </Button>
      {isSuccess && (
        <div>
          Funds Released!
          <Link>
            <a href={`https://etherscan.io/tx/${data?.hash}`}>Etherscan</a>
          </Link>
        </div>
      )}
      {isError && (
        <>
          <Card css={{ mw: "400px" }}>
            <Card.Body>
              <Text>Contract Error: {(prepareError || error)?.message}</Text>
            </Card.Body>
          </Card>
        </>
      )}
    </Container>
  );
}
