import { Card, Col, Grid, Row, Text } from "@nextui-org/react";
import { ethers } from "ethers";
import { useContractReads, useAccount } from "wagmi";
import ROYALTIES_ABI from "../abi/GhoulsRoyalties.json";

const royaltiesContract = {
  addressOrName: "0x283FCF59FCEb9Da8AB7F94bc1dDC294164885d9C",
  contractInterface: ROYALTIES_ABI,
};
export function Releaseable() {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { data, error, isError, isSuccess } = useContractReads({
    contracts: [
      {
        ...royaltiesContract,
        functionName: "releasable(address)",
        args: address,
      },
      {
        ...royaltiesContract,
        functionName: "releasable(address,address)",
        args: ["0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", address],
      },
      {
        ...royaltiesContract,
        functionName: "released(address)",
        args: address,
      },
      {
        ...royaltiesContract,
        functionName: "released(address,address)",
        args: ["0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", address],
      },
    ],
    watch: true,
  });
  if (isDisconnected || isConnecting) return <div>Please Connect Wallet</div>;
  if (isError) return <div>{error?.message}</div>;
  const releaseableEth = data ? ethers.utils.formatEther(data?.[0]) : 0;
  const releaseableWeth = data ? ethers.utils.formatEther(data?.[1]) : 0;
  const releasedEth = data ? ethers.utils.formatEther(data?.[2]) : 0;
  const releasedWeth = data ? ethers.utils.formatEther(data?.[3]) : 0;
  console.log(releasedEth);
  return (
    <>
      <Grid.Container gap={2}>
        <Grid xs={3}>
          <Card variant="bordered">
            <Card.Body>
              <Text>ETH Claimable:</Text>
              <Text>{releaseableEth}</Text>
            </Card.Body>
          </Card>
        </Grid>
        <Grid xs={3}>
          <Card variant="bordered">
            <Card.Body>
              <Text>ETH Claimed:</Text>
              <Text>{releasedEth}</Text>
            </Card.Body>
          </Card>
        </Grid>
        <Grid xs={3}>
          <Card variant="bordered">
            <Card.Body>
              <Text>WETH Claimable:</Text>
              <Text>{releaseableWeth}</Text>
            </Card.Body>
          </Card>
        </Grid>
        <Grid xs={3}>
          <Card variant="bordered">
            <Card.Body>
              <Text>WETH Claimed:</Text>
              <Text>{releasedWeth}</Text>
            </Card.Body>
          </Card>
        </Grid>
      </Grid.Container>
    </>
  );
}
