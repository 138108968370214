import {
  ConnectKitProvider,
  ConnectKitButton,
  getDefaultClient,
} from "connectkit";
import { Connect, Release } from "./components";
import { Container, Grid, NextUIProvider, useTheme } from "@nextui-org/react";

export function App() {
  return (
    <NextUIProvider>
      <ConnectKitProvider>
        <Connect />
        <Container
          md
          alignContent="center"
          alignItems="center"
          justify="center"
        >
          <Release />
        </Container>
      </ConnectKitProvider>
    </NextUIProvider>
  );
}
