import {
  ConnectKitProvider,
  ConnectKitButton,
  getDefaultClient,
} from "connectkit";
import { Navbar, Spacer, Text } from "@nextui-org/react";

export function Connect() {
  return (
    <>
      <Navbar>
        <Text b color="inherit" hideIn="xs">
          Ghouls Creator Royalties
        </Text>
        <ConnectKitButton />
      </Navbar>
      <Spacer y={2} />
    </>
  );
}
